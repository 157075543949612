import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  MenuItem,
  Button,
  Hidden,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PhoneIphoneOutlined, EmailOutlined } from "@material-ui/icons";
import { mainColor } from "./theme";
import { default as activities } from "./activities.json";
import { sendContactMail } from "../../api";

class Label extends React.Component {
  render() {
    return (
      <Typography
        variant="h6"
        style={{ color: "white", marginBottom: 4, marginLeft: 2 }}
      >
        {this.props.children}
      </Typography>
    );
  }
}

class ITextField extends React.Component {
  render() {
    return (
      <TextField
        FormHelperTextProps={{
          style: { margin: 0, padding: 10, backgroundColor: "#0C2F50" },
        }}
        {...this.props}
        size="small"
        variant="outlined"
        style={{ backgroundColor: "white", width: "100%", maxWidth: 500 }}
      />
    );
  }
}

export default class Contact extends React.Component {
  state = { showSubmitMessage: false };

  renderForm(formProps) {
    const {
      errors,
      touched,
      handleSubmit,
      getFieldProps: getFormikFieldProps,
    } = formProps;
    const getErrorProps = (field) => {
      return {
        error: Boolean(touched[field] && errors[field]),
        helperText: touched[field] && errors[field],
      };
    };
    const getFieldProps = (field) => {
      return {
        ...getFormikFieldProps(field),
        ...getErrorProps(field),
      };
    };
    return (
      <Form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Label>Név</Label>
            <ITextField autoComplete="name" {...getFieldProps("name")} />
          </Grid>

          <Grid item>
            <Label>E-mail cím</Label>
            <ITextField autoComplete="email" {...getFieldProps("email")} />
          </Grid>

          <Grid item>
            <Label>Telefonszám</Label>
            <ITextField
              autoComplete="tel-national"
              {...getFieldProps("mobile")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+36</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <Label>Melyik szolgáltatásunk érdekli?</Label>
            <ITextField
              select
              style={{ backgroundColor: "white" }}
              variant="filled"
              {...getFieldProps("selectedService")}
            >
              {activities.map(({ short }, idx) => (
                <MenuItem value={idx}>{short}</MenuItem>
              ))}
            </ITextField>
          </Grid>

          <Grid item>
            <Label>Kérjük, röviden írja le az elképzeléseit!</Label>
            <ITextField multiline rows={6} {...getFieldProps("description")} />
          </Grid>

          <Grid item>
            {this.state.showSubmitMessage && (
              <Label>
                Köszönjük érdeklődését! Hamarosan felvesszünk Önnel a
                kapcsolatot.
              </Label>
            )}
            <Button
              style={{ backgroundColor: "#045782", color: "white", width: 100 }}
              type="submit"
            >
              Küldés
            </Button>
          </Grid>

          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            style={{ marginTop: 5 }}
            spacing={6}
          >
            <Grid item container alignItems="center" style={{ width: "auto" }}>
              <Grid item>
                <PhoneIphoneOutlined
                  fontSize="large"
                  style={{ color: "white" }}
                />
              </Grid>
              <Grid item>
                <Label>+36 70 420 1654</Label>
              </Grid>
            </Grid>

            <Grid item container alignItems="center" style={{ width: "auto" }}>
              <Grid item>
                <EmailOutlined
                  fontSize="large"
                  style={{ color: "white", marginRight: 6 }}
                />
              </Grid>
              <Grid item>
                <Label>info@imhotech.hu</Label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  }

  ContactSchema = Yup.object().shape({
    name: Yup.string().required("Név megadása kötelező."),
    email: Yup.string()
      .required("Az email cím megadása kötelező.")
      .email("Az email cím formátuma nem megfelelő."),
    mobile: Yup.string().required("Telefonszám megadása kötelező"),
    description: Yup.string()
      .required("Leírás megadása kötelező.")
      // eslint-disable-next-line no-template-curly-in-string
      .min(50, "Legalább ${min} karakter hosszú leírás szükséges."),
  });

  render() {
    return (
      <Grid
        container
        wrap="nowrap"
        style={{ marginTop: 102, maxWidth: 1400, marginBottom: 60 }}
      >
        <Hidden mdDown>
          <Avatar
            variant="square"
            src="/images/contact.jpg"
            style={{ width: "100%", height: 892, maxWidth: 550 }}
          />
        </Hidden>
        <Grid
          container
          direction="column"
          style={{
            backgroundColor: mainColor,
            padding: 40,
            paddingLeft: "10%",
            margin: 0,
          }}
        >
          <Typography variant="h4" style={{ color: "white", marginBottom: 30 }}>
            Lépjen velünk kapcsolatba!
          </Typography>
          <Formik
            style={{ maxWidth: "100%" }}
            validationSchema={this.ContactSchema}
            initialValues={{
              name: "",
              email: "",
              mobile: "",
              selectedService: 0,
              description: "",
            }}
            onSubmit={(values, { resetForm }) => {
              const { selectedService, ...rest } = values;
              const contactData = {
                service: activities[selectedService].title,
                ...rest,
              };
              sendContactMail(contactData);
              this.setState({ showSubmitMessage: true });
              resetForm();
            }}
          >
            {(formProps) => this.renderForm(formProps)}
          </Formik>
        </Grid>
      </Grid>
    );
  }
}
