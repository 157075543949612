import React from "react";
import { Dialog, DialogTitle, Grid, Typography } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { default as references } from "./references.json";
import { mainColor } from "./theme";

function ReferenceDialog({ open, onClose, refIdx }) {
  if (refIdx === -1) return null;
  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>{references[refIdx].title}</DialogTitle>

      <Carousel
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={true}
        infiniteLoop={true}
        statusFormatter={(ci, tc) => ci + "/" + tc}
      >
        {Array(references[refIdx].count)
          .fill(0)
          .map((_, i) => (
            <div key={i}>
              <img
                src={`images/references/${refIdx}/${i + 1}.jpg`}
                alt={references[refIdx].title}
              />
            </div>
          ))}
      </Carousel>
    </Dialog>
  );
}

export default class References extends React.Component {
  constructor(p) {
    super(p);

    this.references = [];

    for (let i = references.length - 1; i >= 0; --i) {
      this.references.push({
        ...references[i],
        img: `images/references/${i}/1.jpg`,
        refIdx: i,
      });
    }

    this.handleWindowUpdate = this.updateWindow.bind(this);
  }

  state = {
    carouselMaxWidth: 1000,
    showReferenceDialog: -1,
  };

  componentDidMount() {
    this.updateWindow();
    window.addEventListener("resize", this.handleWindowUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowUpdate);
  }

  updateWindow() {
    this.setState({ carouselMaxWidth: Math.min(1000, window.innerWidth - 40) });
  }

  render() {
    return (
      <Grid
        container
        wrap="nowrap"
        style={{ maxWidth: this.state.carouselMaxWidth, marginTop: 72 }}
        direction="column"
      >
        <ReferenceDialog
          open={this.state.showReferenceDialog >= 0}
          onClose={() => {
            this.setState({ showReferenceDialog: -1 });
          }}
          refIdx={this.state.showReferenceDialog}
        />

        <Grid item>
          <Typography
            variant="h4"
            style={{
              padding: 30,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Referenciák
          </Typography>
        </Grid>

        <Grid item>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            dynamicHeight={true}
            infiniteLoop={true}
            statusFormatter={(ci, tc) => ci + "/" + tc}
            onClickItem={(i) => {
              this.setState({
                showReferenceDialog: this.references.length - 1 - i,
              });
            }}
          >
            {this.references.map(({ title, img }, idx) => (
              <div key={idx}>
                <img src={img} alt={title} />
                <Typography
                  className="legend"
                  style={{
                    backgroundColor: mainColor,
                    bottom: 0,
                    width: "100%",
                    marginLeft: "-50%",
                    borderRadius: 0,
                    fontSize: 14,
                  }}
                >
                  {title}
                </Typography>
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    );
  }
}
