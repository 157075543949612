import axios from "axios";
const basePath = "https://imhotech-functions.vercel.app/api";

async function io(api, data) {
  await axios.post(api, data, {
    baseURL: basePath,
  });
}

export async function sendContactMail(data) {
  await io("sendContactMail", data);
}

export async function sendCarrierMail(data) {
  await io("sendCarrierMail", data);
}
