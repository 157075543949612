import React from "react";
import {
  Introduction,
  Activities,
  Employment,
  References,
  Contact,
} from "./blocks";
import { bodyBackgroundColor } from "./blocks/theme";
import { Grid } from "@material-ui/core";

export default class MainPage extends React.Component {
  fragments = [Introduction, Activities, Employment, References, Contact];

  render() {
    return (
      <>
        {this.fragments.map((_, idx) => {
          const Fragment = this.fragments[idx];

          return (
            <div
              key={idx}
              ref={this.props.contentRefs[idx]}
              style={{ backgroundColor: bodyBackgroundColor }}
            >
              <Grid container justify="center">
                <Fragment />
              </Grid>
            </div>
          );
        })}
      </>
    );
  }
}
