import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { mainDark } from "./main/blocks/theme";

export default class Footer extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ padding: 20, backgroundColor: mainDark }}
      >
        <Grid item container justify="center" style={{ marginBottom: 20 }}>
          {this.props.footerItems.map(({ title, link }) => (
            <Grid item key={title}>
              <Typography style={{ color: "white" }}>{title}</Typography>
            </Grid>
          ))}
        </Grid>

        <Grid item>
          <Typography style={{ color: "white" }}>
            &#169;2021 IMHO TECH KFT
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
