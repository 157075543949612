import React from "react";
import { Grid, Typography, Avatar, Hidden } from "@material-ui/core";
import { mainColor } from "./theme";

export default class Introduction extends React.Component {
  render() {
    return (
      <Grid container wrap="nowrap" style={{ paddingTop: 50, maxWidth: 1400 }}>
        <Grid container item direction="column" justify="flex-end" style={{}}>
          <Grid item>
            <Typography
              style={{
                marginBottom: 90,
                textAlign: "center",
                textTransform: "uppercase",
              }}
              variant="h4"
            >
              Bemutatkozás
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                //  textAlign: "justify",
                backgroundColor: mainColor,
                color: "white",
                padding: 20,
              }}
            >
              Az Imho Tech Kft. 14. éve generálkivitelezéssel, építéssel és
              felújítással foglalkozó építőipari vállalkozás. Legyen szó új
              ingatlan építéséről, vagy régi felújításáról, megbízható és
              szakértő csapatunkkal korszerű otthonok és építmények
              megvalósítására törekszünk. Megrendelőinket biztosítjuk arról,
              hogy a tervdokumentáció alapján, pontosan, szakértő kezek által
              váltjuk valóra elképzeléseit.
            </Typography>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid item>
            <Avatar
              variant="square"
              src="/images/design.jpg"
              style={{
                width: 780,
                height: 520,
                borderStyle: "solid",
                borderWidth: 30,
                borderColor: mainColor,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}
