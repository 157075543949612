import React, { useState } from "react";
import { Grid, Typography, Avatar, Fade, Paper } from "@material-ui/core";
import { default as activities } from "./activities.json";
import { mainColor } from "./theme";

function Details({ content }) {
  const title = content.title ? content.title : null;
  const items = content.details ? content.details : [content];

  return (
    <Grid
      style={{ height: "100%", padding: 10 }}
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={1}
    >
      {title && (
        <Grid item>
          <Typography
            align="center"
            style={{ fontSize: "1rem", fontWeight: 500 }}
            gutterBottom
          >
            {title}:
          </Typography>
        </Grid>
      )}
      {items.map((c) => (
        <Grid item key={c}>
          <Typography
            align="center"
            style={{ fontSize: "0.9rem", lineHeight: 1.65 }}
          >
            {c[0].toUpperCase()}
            {c.slice(1)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

function Activity({ title, img, details }) {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Grid item container direction="column" style={{ width: "auto" }}>
      <Grid item>
        <Typography
          variant="h6"
          style={{
            textTransform: "uppercase",
            marginBottom: 15,
            fontWeight: "bold",
            maxWidth: 300,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
        item
        style={{ position: "relative" }}
      >
        <Avatar
          variant="square"
          style={{
            width: 320,
            height: 320,
          }}
          src={img ? "/images/" + img : "https://via.placeholder.com/320x320"}
        />

        <Fade in={showDetails} timeout={400}>
          <Paper
            elevation={0}
            square
            style={{
              width: 320,
              height: 320,
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: `${mainColor}f0`,
              color: "white",
            }}
          >
            <Details content={details} />
          </Paper>
        </Fade>
      </Grid>
    </Grid>
  );
}

export default class Activities extends React.Component {
  state = {
    prevHoveredIdx: null,
    hoveredIdx: null,
  };

  render() {
    return (
      <Grid container direction="column" style={{ paddingTop: 40 }}>
        <Grid item>
          <Typography
            variant="h4"
            style={{
              padding: 30,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Tevékenységeink
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={8}
          justify="center"
          alignItems="flex-end"
          style={{ padding: 20, width: "100%", margin: 0 }}
        >
          {activities.map(({ title, img, details }, idx) => (
            <Activity key={idx} title={title} img={img} details={details} />
          ))}
        </Grid>
      </Grid>
    );
  }
}
