import React from "react";
import {
  Grid,
  Typography,
  Avatar,
  Hidden,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Set } from "immutable";
import { mainColor } from "./theme";
import { sendCarrierMail } from "../../api";

class Label extends React.Component {
  render() {
    return (
      <Typography variant="h6" style={{ marginBottom: 4, marginLeft: 2 }}>
        {this.props.children}
      </Typography>
    );
  }
}

class ITextField extends React.Component {
  render() {
    return (
      <TextField
        {...this.props}
        size="small"
        variant="outlined"
        style={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: 500,
          marginBottom: 15,
        }}
      />
    );
  }
}

export default class Tenders extends React.Component {
  state = {
    dialogOpen: false,
    showSubmitMessage: false,
  };

  types = ["Alkalmazott", "Brigád", "Alvállalkozó"];
  workTypes = [
    "Segédmunka",
    "Festés",
    "Burkolás",
    "Szigetelés",
    "Kőműves munka",
    "Épületgépészet",
    "Gipszkartonozás",
    "Villanyszerelés",
    "Ácsmunka",
    "Egyéb (a referenciákban kifejtem)",
  ];

  ApplySchema = yup.object().shape({
    employmentType: yup.string().required().oneOf(this.types),
    name: yup.string().required("Név vagy cégnév megadása kötelező."),
    email: yup
      .string()
      .required("Email cím megadása kötelező.")
      .email("Az email cím formátuma nem megfelelő."),
    phone: yup.string().required("A telefonszám megadása kötelező"),
    teamSize: yup.string().when("employmentType", {
      is: (val) => val === "Brigád" || val === "Alvállalkozó",
      then: yup.string().required("A csapat létszámát kötelező megadni."),
    }),
    works: yup
      .array(yup.string().oneOf(this.workTypes))
      // eslint-disable-next-line no-template-curly-in-string
      .min(1, "Legalább ${min} munka típust kötelező választani."),
    address: yup
      .string()
      .required("A lakhely vagy székhely megadása kötelező."),
    experience: yup.string().required("Az évek számát kötelező megadni."),
    references: yup
      .string()
      .required("A referenciákat kötelező megadni.")
      // eslint-disable-next-line no-template-curly-in-string
      .min(100, "Legalább ${min} karaktert kell írni."),
  });

  renderForm(formProps) {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      getFieldProps: getFormikFieldProps,
      setFieldValue,
    } = formProps;
    const getErrorProps = (field) => {
      return {
        error: Boolean(touched[field] && errors[field]),
        helperText: touched[field] && errors[field],
      };
    };
    const getFieldProps = (field) => {
      return {
        ...getFormikFieldProps(field),
        ...getErrorProps(field),
      };
    };
    let workTypesSelected = Set(values.works);
    return (
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Label>Foglalkoztatási forma</Label>
          <ITextField select {...getFieldProps("employmentType")}>
            {this.types.map((v) => (
              <MenuItem value={v}>{v}</MenuItem>
            ))}
          </ITextField>

          <Label>Név / Cégnév</Label>
          <ITextField autoComplete="name" {...getFieldProps("name")} />

          <Label>Email cím</Label>
          <ITextField autoComplete="email" {...getFieldProps("email")} />

          <Label>Telefonszám</Label>
          <ITextField autoComplete="phone" {...getFieldProps("phone")} />

          {values.employmentType !== "Alkalmazott" && (
            <>
              <Label>Hány fős csapatban dolgoznak?</Label>
              <ITextField type="number" {...getFieldProps("teamSize")} />
            </>
          )}

          <Label>Milyen munkákat vállal?</Label>
          <FormGroup style={{ marginBottom: 15 }}>
            {this.workTypes.map((v) => (
              <FormControlLabel
                key={v}
                control={
                  <Checkbox
                    color="primary"
                    checked={workTypesSelected.has(v)}
                    onChange={(e) => {
                      workTypesSelected = e.target.checked
                        ? workTypesSelected.add(v)
                        : workTypesSelected.remove(v);
                      setFieldValue("works", workTypesSelected.toArray());
                    }}
                  />
                }
                label={v}
              />
            ))}
          </FormGroup>

          <Label>Állandó lakhely / Cég székhelye</Label>
          <ITextField autoComplete="address" {...getFieldProps("address")} />

          <Label>Hány év szakmai tapasztalattal rendelkezik?</Label>
          <ITextField type="number" {...getFieldProps("experience")} />

          <Label>
            Milyen munkákon dolgozott eddig, milyen referenciái vannak?
          </Label>
          <ITextField multiline rows={8} {...getFieldProps("references")} />

          {this.state.showSubmitMessage && (
            <Label>
              Köszönjük érdeklődését! Hamarosan felvesszük Önnel a kapcsolatot.
            </Label>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ dialogOpen: false })}>
            Mégsem
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Jelentkezés küldése
          </Button>
        </DialogActions>
      </Form>
    );
  }

  ApplyDialog() {
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={() => this.setState({ dialogOpen: false })}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Jelentkezzen munkára
        </DialogTitle>

        <Formik
          initialValues={{
            employmentType: this.types[0],
            name: "",
            email: "",
            phone: "",
            teamSize: "",
            works: [],
            address: "",
            experience: "",
            references: "",
          }}
          validationSchema={this.ApplySchema}
          onSubmit={async (values, { resetForm }) => {
            sendCarrierMail(values);
            this.setState({ showSubmitMessage: true });
            resetForm();
          }}
        >
          {(formProps) => this.renderForm(formProps)}
        </Formik>
      </Dialog>
    );
  }
  render() {
    return (
      <Grid container wrap="nowrap" style={{ paddingTop: 50, maxWidth: 1200 }}>
        {this.ApplyDialog()}
        <Grid container item direction="column" justify="flex-end" style={{}}>
          <Grid item>
            <Typography
              style={{
                marginBottom: 90,
                textAlign: "center",
                textTransform: "uppercase",
              }}
              variant="h4"
            >
              Karrier
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              backgroundColor: mainColor,
              color: "white",
              padding: 20,
            }}
          >
            <Typography variant="h6">
              Az Imho Tech Kft. bővülő csapatába keresünk új alkalmazottakat és
              alvállalkozókat. Ha szeretne velünk dolgozni, kérjük töltse ki az
              űrlapot és felvesszük Önnel a kapcsolatot!
            </Typography>
            <Button
              onClick={() => this.setState({ dialogOpen: true })}
              style={{
                backgroundColor: "#045782",
                color: "white",
                marginTop: 20,
              }}
            >
              Kitöltöm az űrlapot
            </Button>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Avatar
              variant="square"
              src="/images/tender.jpg"
              style={{
                width: 540,
                height: 380,
                borderStyle: "solid",
                borderWidth: 30,
                borderColor: mainColor,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}
