import React from "react";
import MainPage from "./main/MainPage";
import Header from "./Header";
import Footer from "./Footer";

class App extends React.Component {
  menuItems = [
    { title: "Bemutatkozás" },
    { title: "Tevékenységeink" },
    { title: "Karrier" },
    { title: "Referenciák" },
    { title: "Kapcsolat" },
  ];

  contentRefs = this.menuItems.map(() => React.createRef());
  footerItems = [{ title: "Impresszum", link: "" }];

  render() {
    return (
      <>
        <Header
          menuItems={this.menuItems}
          onClick={(idx) =>
            this.contentRefs[idx].current.scrollIntoView({
              behavior: "smooth",
            })
          }
        />
        <MainPage contentRefs={this.contentRefs} />
        <Footer footerItems={this.footerItems} />
      </>
    );
  }
}

export default App;
