import React from "react";
import {
  Grid,
  Button,
  Hidden,
  Typography,
  AppBar,
  Toolbar,
  Collapse,
  IconButton,
  ClickAwayListener,
  List,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { mainColor, mainDark } from "./main/blocks/theme";

class CollapseMenu extends React.Component {
  state = {
    menuOpen: false,
  };

  render() {
    return (
      <ClickAwayListener onClickAway={() => this.setState({ menuOpen: false })}>
        <AppBar
          style={{ backgroundColor: mainDark }}
          elevation={0}
          position="fixed"
        >
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item>
              <img
                src="images/main_logo.png"
                style={{ maxWidth: 200, width: "100%", height: "auto" }}
                alt="Main Logo"
              />
            </Grid>

            <Grid item>
              <IconButton
                onClick={() =>
                  this.setState({ menuOpen: !this.state.menuOpen })
                }
                variant="outlined"
              >
                <MenuIcon style={{ color: "white", fontSize: 30 }} />
              </IconButton>
            </Grid>
          </Toolbar>

          <div
            style={{
              backgroundColor: mainDark,
              position: "absolute",
              top: "70px",
              width: "100%",
            }}
          >
            <Collapse in={this.state.menuOpen}>
              <List>
                {this.props.menuItems.map(({ title }, idx) => (
                  <ListItem
                    key={title}
                    button
                    onClick={() => {
                      this.setState({ menuOpen: false });
                      this.props.onClick(idx);
                    }}
                    style={{ paddingLeft: 30 }}
                  >
                    <Typography style={{ color: "white" }}>{title}</Typography>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        </AppBar>
      </ClickAwayListener>
    );
  }
}

class RegularMenu extends React.Component {
  render() {
    return (
      <>
        <Grid
          item
          container
          justify="space-between"
          style={{ backgroundColor: mainDark, position: "fixed", zIndex: 999 }}
        >
          <Hidden mdDown>
            <Grid item>
              <img
                src="images/main_logo.png"
                style={{ maxWidth: 400 }}
                alt="Main Logo"
              />
            </Grid>
          </Hidden>
          <Grid
            item
            container
            justify="flex-end"
            style={{
              paddingTop: 50,
              paddingRight: 10,
              width: "auto",
            }}
          >
            {this.props.menuItems.map(({ title }, idx) => (
              <Grid item style={{ padding: 10 }} key={idx}>
                <Button
                  style={{
                    color: "white",
                    fontWeight: 600,
                    paddingRight: 15,
                    paddingLeft: 15,
                    borderRadius: 0,
                    borderColor: "white",
                    letterSpacing: 0.7,
                    fontSize: 16,
                  }}
                  onClick={() => this.props.onClick(idx)}
                >
                  {title}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <div
          style={{
            backgroundImage: `url("/images/buildings.jpg")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 600,
            backgroundColor: "#f5f5f5",
          }}
        />

        <div
          style={{
            position: "absolute",
            top: 250,
            backgroundColor: mainColor,
            color: "white",
            paddingBottom: 20,
            paddingTop: 30,
            paddingLeft: 60,
            paddingRight: 50,
          }}
        >
          <Typography
            style={{ textTransform: "uppercase", marginBottom: 10 }}
            variant="h5"
          >
            Tervezés és generálkivitelezés
          </Typography>

          <Typography style={{ maxWidth: 700, marginBottom: 10 }}>
            Az Imho Tech Kft. generálkivitelezéssel, épületbontással, építéssel
            és felújítással foglalkozó építőipari vállalkozás. Töltse ki
            ajánlatkérő lapunkat és felvesszük Önnel a kapcsolatot!{" "}
          </Typography>

          <Button
            variant="contained"
            style={{ backgroundColor: "#045782", color: "white" }}
            onClick={() => this.props.onClick(4)}
          >
            Ajánlatkérés
          </Button>
        </div>
      </>
    );
  }
}

export default class Header extends React.Component {
  render() {
    return (
      <>
        <Hidden mdDown>
          <RegularMenu
            onClick={this.props.onClick}
            menuItems={this.props.menuItems}
          />
        </Hidden>
        <Hidden lgUp>
          <CollapseMenu
            onClick={this.props.onClick}
            menuItems={this.props.menuItems}
          />
          <div style={{ height: 80, backgroundColor: "#F5F5EF" }} />
        </Hidden>
      </>
    );
  }
}
